import React from "react"

import Layout from "../components/layout"
import { CookieComponent } from '../components/cookieComponent' 
import SEO from '../components/seo'

const BydgoszczCookies = () => (
  <Layout bydgoszcz>
    <SEO
      title='Bydgoszcz Cookies'
      description='Cookies'
      url='https://bodymedgroup.pl/bydgoszcz-cookies'
    />

    <CookieComponent/>
  </Layout>
)

export default BydgoszczCookies
